<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="12">
        <b-card>
          <h1>{{ title }}</h1>
          <div class="mt-2">
            <h3>Filtres de recherche</h3>
            <StatisticsFilters v-model="filtersForm" />
            <!-- :active-filters="['periode']" -->
            <b-button
              class="float-right"
              variant="primary"
              @click="getStatistics()"
            >
              <!-- <feather-icon icon="SearchIcon" class="mr-25" /> -->
              <b-spinner small v-if="isLoading"></b-spinner>
              <span>Filtrer</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12">
        <b-card>
          <div>
            <h4>Charges salariales et chiffres d'affaires</h4>
            <b-row>
              <b-col md="6">
                <h5>Total des Charges salariales</h5>
                <p class="text-primary">
                  <strong>
                    {{
                      formatCurrency(
                        (totalsValues && totalsValues.chargeSalariale) || 0
                      )
                    }}
                  </strong>
                </p>
              </b-col>
              <b-col md="6">
                <h5>Chiffre d'affaires total</h5>
                <p class="text-primary">
                  <strong>
                    {{
                      formatCurrency(
                        (totalsValues && totalsValues.chiffreAffaireGlobal) || 0
                      )
                    }}
                  </strong>
                </p>
              </b-col>
            </b-row>
          </div>
          <hr />
          <div>
            <h4>Arrivées et durée de séjour</h4>
            <b-row>
              <b-col md="6">
                <h5>Nombre total d'arrivée</h5>
                <p class="text-primary">
                  <strong>
                    {{ (totalsValues && totalsValues.nombreArrives) || 0 }}
                  </strong>
                  &nbsp; arrivées
                </p>
              </b-col>
              <b-col md="6">
                <h5>Durée moyenne de séjour</h5>
                <p class="text-primary">
                  <strong>
                    {{
                      (totalsValues && totalsValues.moyenneDureeSejour) || 0
                    }}
                    jours
                  </strong>
                </p>
              </b-col>
            </b-row>
          </div>
          <hr />
          
          <div>
            <h4>Statistiques sur les nombre de nuitées</h4>
            <p class="text-primary">
              <b-row>
                <b-col md="6">
                  Nombre total de nuitées :
                  <strong>
                    {{ (totalsValues && totalsValues.nombreNuites) || 0 }}
                  </strong>
                </b-col>
                <b-col md="6">
                  Nombre moyen de nuitées :
                  <strong>
                    {{
                      (totalsValues && totalsValues.moyenneNombreNuites) || 0
                    }}
                  </strong>
                </b-col>
              </b-row>
              <!-- <strong>
                {{ (totalsValues && totalsValues.nombreNuites) || 0 }}
              </strong> -->
            </p>
          </div>
          <hr />
        </b-card>
      </b-col>
      <b-col lg="6">
        <StatsDepensesSalariales
          :is-loading="isLoading"
          :series="chartSeries.StatsDepensesSalariales"
        />
      </b-col>
      <b-col lg="6">
        <StatsChiffreAffaire
          :is-loading="isLoading"
          :series="chartSeries.StatsChiffreAffaire"
        />
      </b-col>
      <b-col lg="6">
        <StatsNombreArrives
          :is-loading="isLoading"
          :series="chartSeries.StatsNombreArrives"
        />
      </b-col>
      <b-col lg="6">
        <StatsNombreNuites
          :is-loading="isLoading"
          :series="chartSeries.StatsNombreNuites"
        />
      </b-col>
      <b-col lg="12">
        <StatsGlobalesStackedArea
          :is-loading="isLoading"
          :series="chartSeries.StatsGlobalesStackedArea"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState, mapGetters } from "vuex";
import utilsService from "@/services/utils/utils.service";

// store modules and vuex utilities
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

const StatsGlobalesStackedArea = () => import("@/views/statistics/etablissements/exploitation-etablissement/StatsGlobalesStackedArea.vue")
const StatsDepensesSalariales = () => import("@/views/statistics/etablissements/exploitation-etablissement/StatsDepensesSalariales.vue")
const StatsChiffreAffaire = () => import("@/views/statistics/etablissements/exploitation-etablissement/StatsChiffreAffaire.vue")
const StatsNombreNuites = () => import("@/views/statistics/etablissements/exploitation-etablissement/StatsNombreNuites.vue")
const StatsNombreArrives = () => import("@/views/statistics/etablissements/exploitation-etablissement/StatsNombreArrives.vue")
const StatisticsFilters = () => import("@/components/StatisticsFilters.vue")
const ToastificationContent = () => import("@core/components/toastification/ToastificationContent.vue")

const monthsList = utilsService.getListMonths();
export default {
    setup() {
    const requiredStoreModules = [
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  }, 
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardText,
    StatsGlobalesStackedArea,
    StatsDepensesSalariales,
    StatsChiffreAffaire,
    StatsNombreNuites,
    StatsNombreArrives,
    StatisticsFilters,
    BButton,
    BSpinner,
    ToastificationContent,
  },
  data() {
    return {
      isLoading: false,
      chartSeries: {},
      monthsList,
      filtersForm: {},
      statisticsRequestData: null,
    };
  },
  props: {
    etablissementId: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
  },
  created() {},
  async mounted() {
    await this.getStatistics();
  },
  methods: {
    ...mapActions("statistics", {
      action_getStatisticsExploitationEtablissements:
        "getStatisticsExploitationEtablissements",
    }),
    buildFiltersObjectFor() {
      let filtersForm = { ...this.filtersForm };
      if (!filtersForm.periode || !filtersForm.periode.from) {
        filtersForm.periode = {
          from: "2022/01/20",
          to: "2022/09/14",
        };
      }

      // else {
      filtersForm.periode = [
        filtersForm.periode.from.replace(/\//g, "-"),
        ",",
        filtersForm.periode.to.replace(/\//g, "-"),
      ].join("");
      // }
      const filtersData = {
        // commune: {
        //   filtersFormKey: "commune",
        //   apiQueryKey: "commune",
        // },
        // departement: {
        //   filtersFormKey: "departement",
        //   apiQueryKey: "departement",
        // },
      };

      if (filtersForm.periode)
        filtersData.periode = {
          filtersFormKey: "periode",
          apiQueryKey: "createdAt",
        };
      let filtre = { type: [], text: [] };
      Object.values(filtersData).forEach((filterItem) => {
        const queryValue = filtersForm[filterItem.filtersFormKey];
        if (queryValue && queryValue !== "") {
          filtre.type.push(filterItem.apiQueryKey),
            filtre.text.push(queryValue);
        }
      });
      // console.log("filtre", filtre, filtersForm);
      return { filtre };
    },
    async getStatistics(data) {
      this.isLoading = true;
      // console.log("filters", this.filtersForm);
      const processedFilters = this.buildFiltersObjectFor();
      const queryParams = {
        tables: [
          "formstattransport",
          "formstatrestau",
          "formstathotel",
          "formstatagence",
          "formstatlocationvehiculesansop",
        ],
        options: {
          // etablissementId: this.etablissementId,
          // etablissementId: '62fe028fb4323e6cf888afd5',
        },
        filtre: processedFilters.filtre,
      };
      await this.action_getStatisticsExploitationEtablissements(queryParams)
        .then((res) => {
          const { originalResponse, processedResponse } = res;
          const { data } = originalResponse;
          const { options, total } = data.resources;
          this.statisticsRequestData = data.resources;
          // console.log("res statistics", data);
          const { series } = processedResponse;
          this.chartSeries = series;
          this.isLoading = false;

          // console.log('stats types ets', res)
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          // console.log(error);
        });
    },
  },
  computed: {
    totalsValues() {
      if (!this.statisticsRequestData || !this.statisticsRequestData.total)
        return null;
      return this.statisticsRequestData.total;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
